import React, { useEffect, useState } from 'react';
import { AmplifyAuthProvider } from 'src/auth/auth';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { ssoWebsiteRoutes } from '@amzn/aes-maestro-website-core/dist';
import AppProvider from '@amzn/aes-maestro-website-core/dist/components/providers/AppProvider';
import AmplifyFetch from 'src/utils/amplify-fetch';
import Spinner from '@amzn/aes-maestro-website-core/dist/components/base/Spinner/Spinner';

function App() {
  const [isUserAuthenticated, setIsUserAuthenticated] = useState(false);

  useEffect(() => {
    /**
     * Authenticate user.
     */
    async function authenticateUser() {
      const user = await AmplifyAuthProvider.authenticateUser();
      // set user authenticated.
      setIsUserAuthenticated(!!user);
    }

    authenticateUser();
  }, []);

  if (!isUserAuthenticated) return null;

  return (
    <AppProvider fetcher={AmplifyFetch}>
      <React.Suspense fallback={<Spinner />}>
        <RouterProvider router={createBrowserRouter(ssoWebsiteRoutes)} />
      </React.Suspense>
    </AppProvider>
  );
}

export default App;
